<template>
  <div class="pageBox">
    <!-- <div class="searchBox">
      <div class="row">
        <span class="span">商品名称：</span>
        <div class="right"><el-input v-model="goods_name" class="wid100" clearable></el-input></div>
      </div>
      <div class="row">
        <el-button type="primary" @click="handleSearch"><el-icon><Search /></el-icon>&nbsp;筛选</el-button>
      </div>
    </div> -->

    <el-card shadow="never">
      <!-- <div class="opaBox">
        <el-button type="warning" @click="handleExport"><span class="iconfont icon-daochu"></span>&nbsp;导出</el-button>
      </div> -->
      <el-table :data="goodsList" style="width: 100%" border v-loading="loading">
        <el-table-column prop="id" label="ID" width="80" align="center" />
        <el-table-column prop="target_type" label="来源" align="center" />
        <el-table-column prop="goods.title" label="商品名称" align="center" />
        <el-table-column prop="goods_id" label="商品编码" align="center" />
        <el-table-column prop="sku.title" label="规格名称" align="center" />
        <el-table-column prop="sku.sku_code" label="规格编码" align="center" />
        <el-table-column prop="num" label="数量" align="center" />
        <el-table-column prop="cost" label="成本" align="center" />
        <el-table-column prop="production_date" label="生产日期" align="center" />
        <el-table-column prop="factory_date" label="出厂日期" align="center" />
        <el-table-column prop="validity" label="有效时间" align="center">
          <template #default="scope">
            <span>{{scope.row.validity_type == 'none' ? '-' : scope.row.validity}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="validity_type" label="有效期类型" align="center">
          <template #default="scope">
            <span>{{Validity[scope.row.validity_type]}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="validity_end_time" label="截止有效期" align="center" />
        <el-table-column prop="batch_number" label="批次号" align="center" />
        <el-table-column prop="created_at" label="操作时间" align="center" />
      </el-table>
      <div class="page-pagination">
        <el-pagination
          :current-page="page"
          background
          layout="prev, pager, next, sizes, total"
          :total="total"
          :page-sizes="[10, 50, 100]"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"></el-pagination>
      </div>
    </el-card>

  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue"
import { get, post } from "@/api/request"
import { Search, Plus, Edit, ZoomIn, Delete } from '@element-plus/icons'
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
  components: {
    Search, Plus, Edit, ZoomIn, Delete
  },
  setup() {
    const data = reactive({
      goodsList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      Validity: {
        'day': '天',
        'month': '月',
        'year': '年',
        'none': '长期'
      }
    })

    function handleSearch() {
      data.page = 1
      fetchData()
    }

    const fetchData = () => {
      data.loading = true
      let params = {
        page: data.page,
        pageSize: data.pageSize,
        service_id: data.service_id,
        start_date: data.pickerTime ? data.pickerTime[0] : '',
        end_date: data.pickerTime ? data.pickerTime[1] : ''
      }
      get(`/api/goods-put-records`, params).then((res) => {
        data.goodsList = res.data
        data.total = res.meta.total
        data.loading = false
      }).catch(() => {
        data.loading = false
      })
    }

    function handleCurrentChange(e) {
      data.page = e
      fetchData()
    }

    function handleSizeChange(e) {
      data.page = 1
      data.pageSize = e
      fetchData()
    }

    onMounted(() => {
      fetchData()
    })

    return {
      ...toRefs(data),
      handleSearch,
      handleCurrentChange,
      handleSizeChange,
      fetchData,

    }
  }
}
</script>

<style lang="scss" scoped>
  .searchBox{
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    padding: 15px 0 0 0;
    border-radius: 4px;
    margin-bottom: 15px;
    .row{
      display: flex;
      align-items: center;
      width: 20%;
      box-sizing: border-box;
      margin-bottom: 15px;
      &.row1{
        width: 25%;
      }
      .span{
        display: block;
        width: 80px;
        font-size: 14px;
        text-align: right;
        box-sizing: border-box;
      }
      .right{
        width: calc(100% - 100px);
      }
      .wid100{
        width: 100%;
      }
    }
  }
  .opaBox{
    margin-bottom: 15px;
  }
  .imgBox{
    display: flex;
    flex-wrap: wrap;
    .el-image{
      width: 60px;
      height: 60px;
      border-radius: 4px;
      margin-right: 10px;
    }
  }
  .skuBox{
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 15px 0;
    margin-bottom: 15px;
    background-color: #f3f3f3;
    .tit{
      padding-left: 40px;
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 15px;
    }
  }
</style>
